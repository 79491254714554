<template>
  <div class="meta-box">
    <h3>{{ $t(`promotion.${campaignName}.banner1`) }}</h3>
    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <div
          class="descp3"
          v-html="
            optIn ? $t(`promotion.${campaignName}.optOutDescription`) : $t(`promotion.${campaignName}.optInDescription`)
          "
        ></div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button
          class="el-button btn-blue long-button"
          data-testid="opt"
          @click="preDespositBonusOpt"
          v-html="optIn ? $t(`promotion.${campaignName}.signOut`) : $t(`promotion.${campaignName}.activate`)"
        >
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="sub-box2">
      <el-col :xs="24">
        <div class="descp2 " v-html="$t(`promotion.${campaignName}.claimDescription`)"></div>
      </el-col>
      <el-col :xs="24">
        <div class="descp2 " v-html="$t(`promotion.${campaignName}.eligibleDescription`)"></div>
      </el-col>
      <el-col :xs="24" class="button-box">
        <el-button class="el-button btn-blue long-button" data-testid="fund" @click="goToFund" :disabled="!optIn">
          {{ $t(`promotion.${campaignName}.fund`) }}</el-button
        >
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n :path="`promotion.${campaignName}.tnc`">
          <template v-slot:link>
            <a :href="getTncLink()" target="_blank" data-testid="tnc">
              {{ $t(`promotion.${campaignName}.link`) }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>
    <AgreeTnc
      v-if="campaignName === 'depositBonus'"
      :agreeTncVisible.sync="agreeTncVisible"
      @isAgreeTnc="isAgreeTncHandler"
      :tncContent="`promotion.tnc3`"
    ></AgreeTnc>
    <AgreeTncV2
      v-else
      :agreeTncVisible.sync="agreeTncVisible"
      :headerText="`promotion.${campaignName}.headerText`"
      :content="`promotion.${campaignName}.agreeTnc`"
      @isAgreeTnc="isAgreeTncHandler"
    ></AgreeTncV2>
    <CallbackDialog
      v-if="campaignName === 'depositBonus'"
      :show.sync="successCallback"
      :successCallbackType="successCallbackType"
      :campaignName="campaignName"
    />
    <NewCallbackDialog
      v-else
      :show.sync="successCallback"
      :successCallbackType="successCallbackType"
      :campaignName="campaignName"
    />
  </div>
</template>

<script>
import { apiCampaignOpt } from '@/resource';
import AgreeTnc from '@/components/promotion/common/AgreeTnc';
import AgreeTncV2 from '@/components/promotion/deposit20Bonus/AgreeTnc';
import CallbackDialog from '@/components/promotion/depositBonus/CallbackDialog';
import NewCallbackDialog from '@/components/promotion/deposit20Bonus/CallbackDialog';
import blackList from '@/mixins/blackList';

export default {
  name: 'fundAccount',
  components: {
    AgreeTnc,
    CallbackDialog,
    AgreeTncV2,
    NewCallbackDialog
  },
  props: { optIn: Boolean, campaignName: String, campaignID: Number },
  mixins: [blackList],
  data() {
    return {
      form: {
        checked: true
      },
      successCallback: false,
      successCallbackType: null,
      agreeTncVisible: false,
      isAgreeTnc: false,
      tncList: {
        deposit20Bonus: {
          svg: `https://vn.puprime.net/pdf/0528/Vietnam_Deposit_Bonus_Term_and_Conditions_Net.pdf?v=1613980771`,
          fsa: `https://vn.puprime.com/pdf/0528/Vietnam_Deposit_Bonus_Term_and_Conditions.pdf?v=1613980771`
        }
      }
    };
  },
  watch: {
    optIn: {
      immediate: true,
      handler(val) {
        this.form.checked = val;
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    goToFund() {
      this.getDepositBlacklist();
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    isAgreeTncHandler(val) {
      if (val) {
        this.postDespositBonusOpt();
      }
    },
    preDespositBonusOpt() {
      this.agreeTncVisible = this.form.checked ? this.postDespositBonusOpt() : true;
    },
    postDespositBonusOpt() {
      this.form.checked = !this.form.checked;
      apiCampaignOpt(this.campaignID, this.form.checked)
        .then(resp => {
          if (resp.data.data) {
            this.successCallback = true;
            this.successCallbackType = this.form.checked;
            this.$emit('successfulCallback');
          } else {
            this.showError();
            this.form.checked = this.optIn;
          }
        })
        .catch(err => {
          this.showError();
          this.form.checked = this.optIn;
        });
    },
    getTncLink() {
      return this.campaignName === 'depositBonus'
        ? `https://${this.GLOBAL_DOMAIN_WEBSITE}/pdf/0528/Credit_Bonus_Term_and_Condition_2.pdf?v=1626931525`
        : this.tncList[this.campaignName][this.regulator];
    }
  }
};
</script>
